<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Luo voimassa oleva vuokrasopimus</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>

    <OldRentalContractForm btn-text="Luo" @sendcontract="createContract($event)">
    </OldRentalContractForm>
  </div>
</template>

<script>
import axiosMethods from "../../mixins/axios";
import mixins from "../../mixins/mixins";
import OldRentalContractForm from "../../components/RentalContract/OldRentalContract/OldRentalContractForm";

export default {
  title: "Luo voimassa oleva vuokrasopimus",

  components: {
    OldRentalContractForm,
  },

  mixins: [mixins],

  methods: {
    async createContract(data) {
      try {
        const res = await axiosMethods.post("/api/v1/rental-contract/createOld/", data);

        if (typeof res.data.emailCount === "number" && res.data.emailCount > 0) {
          this.showPopup(
            `Samalla sähköpostilla löytyy ${res.data.emailCount} ${
              res.data.emailCount === 1 ? "muu vuokralainen" : "muuta vuokralaista"
            }. Tarkista, ettet vahingossa asettanut väärää email-osoitetta.`,
            "info"
          );
        }

        this.showPopup("Voimassa olevan vuokrasopimuksen luominen onnistui", "success");
        this.$router.push("/rental-contracts");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped></style>
